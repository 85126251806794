import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import apiClient from "../apiClient";

export interface BxAPIFilter {
    id?: string;
    property: string;
    value: string | number | boolean | null | (number | string)[];
    operator?: "=" | "gt" | "in" | "!=" | "<=" | ">=";
    ftype?: "inject";
}

interface UseData<T = any> {
    isLoading: boolean;
    data: T[];
    queryWithDelay: string;
    setQueryWithDelay: React.Dispatch<React.SetStateAction<string>>;
    setQueryFilter: React.Dispatch<React.SetStateAction<string>>;
    filters: BxAPIFilter[];
    setFilters: React.Dispatch<React.SetStateAction<BxAPIFilter[]>>;
    addFilters: (newFilters: BxAPIFilter[]) => void;
    setFilter: (id: string, filter: BxAPIFilter) => void;
    removeFilter: (id: string) => void;
    load: () => Promise<unknown>;
    totalCount: number | undefined;
    error: unknown;
    deleteRecord: (record: T) => Promise<unknown>;
    deleteIds: (ids: number[]) => Promise<unknown>;
    isDeleting: boolean;
    isUpdating: boolean;
    isAdding: boolean;
    doUpdate: (record: { Id: number } & Partial<T>) => Promise<unknown>;
    doAdd: (record: Partial<T>) => Promise<BxAPIResult<Partial<T>>>;
    addError: unknown;
}

export interface BxAPIResult<T = any> {
    success: boolean;
    message: string | null;
    error: string | null;
    totalcount: number;
    data: T[];
}

export interface UseDataConfig {
    pageOffset?: number;
    pageSize?: number;
    requireFilter?: boolean;
    refetchInterval?: number | false;
    refetchOnWindowFocus?: boolean;
    initFilters?: BxAPIFilter[];
    enabled?: boolean;
    suspense?: boolean;
}

const useData = <T = any>(
    endpoint: string,
    config?: UseDataConfig
): UseData<T> => {
    const {
        pageOffset = 0,
        pageSize = 25,
        requireFilter = false,
        refetchInterval = false,
        initFilters = [],
        enabled = true,
        suspense = false,
        refetchOnWindowFocus = false,
    } = config || {};
    const queryClient = useQueryClient();
    const [queryWithDelay, setQueryWithDelay] = useState("");
    const [queryFilter, setQueryFilter] = useState("");
    const [filters, setFilters] = useState<BxAPIFilter[]>(initFilters);

    const requestFilters = useMemo(() => {
        const cleanedFilters: BxAPIFilter[] = filters.reduce(
            (clean: BxAPIFilter[], filter) => {
                if (filter.value === undefined) {
                    return clean;
                } else {
                    return [
                        ...clean,
                        { ...filter, id: undefined }, //removed id
                    ];
                }
            },
            []
        );

        return queryFilter
            ? [{ property: "query", value: queryFilter }, ...cleanedFilters]
            : cleanedFilters;
    }, [filters, queryFilter]);

    const queryKey = [endpoint, requestFilters, pageSize, pageOffset];

    const {
        data: apiData,
        isFetching,
        error,
    } = useQuery(
        queryKey,
        async ({ signal }) => {
            const res = await apiClient.get<BxAPIResult<T>>(`${endpoint}`, {
                signal,
                params: {
                    filter:
                        requestFilters.length > 0
                            ? JSON.stringify(requestFilters)
                            : undefined,
                    start: pageSize * pageOffset,
                    limit: pageSize,
                },
            });
            return res.data;
        },
        {
            enabled:
                enabled &&
                (!requireFilter ||
                    filters.length > 0 ||
                    queryFilter.length > 0),
            refetchInterval: refetchInterval,
            suspense,
            refetchOnWindowFocus: refetchOnWindowFocus,
        }
    );

    const load = useCallback(
        () => queryClient.invalidateQueries([endpoint]),
        [endpoint, queryClient]
    );

    const addFilters = useCallback((newFilters: BxAPIFilter[]) => {
        setFilters((state) => [...state, ...newFilters]);
    }, []);

    const setFilter = useCallback((id: string, filter: BxAPIFilter) => {
        const filterWithId = { ...filter, id };
        setFilters((state) => {
            const filterExists = state.find((i) => i.id === id);
            if (filterExists) {
                return state.map((f) => (f.id === id ? filterWithId : f));
            } else {
                return [...state, filterWithId];
            }
        });
    }, []);

    const removeFilter = useCallback((id: string) => {
        setFilters((state) => state.filter((i) => i.id !== id));
    }, []);

    const { isLoading: isDeleting, mutate: doDelete } = useMutation(
        async (id: string | number | number[]) => {
            if (Array.isArray(id)) {
                await apiClient.delete(`${endpoint}`, {
                    data: id.map((i) => ({ id: i })),
                });
            } else {
                await apiClient.delete(`${endpoint}/${id}`);
            }

            queryClient.setQueryData(
                queryKey,
                (old: BxAPIResult<T> | undefined): BxAPIResult<T> => {
                    if (!old) {
                        console.error(
                            "Unable to remove record from query, query already empty :/"
                        );
                        return {
                            totalcount: 0,
                            data: [],
                            error: null,
                            message: null,
                            success: false,
                        };
                    }
                    return {
                        ...old,
                        totalcount: old.totalcount - 1,
                        data: old.data.filter((row) => {
                            if (Array.isArray(id)) {
                                // @ts-ignore
                                return !id.includes(row.id);
                            } else {
                                // @ts-ignore
                                return row.id !== id;
                            }
                        }),
                    };
                }
            );
        }
    );

    const { isLoading: isUpdating, mutateAsync: doUpdate } = useMutation(
        async (record: { Id: string | number } & Partial<T>) => {
            await apiClient.put(`${endpoint}/${record.Id}`, record);

            queryClient.setQueryData(
                queryKey,
                (old: BxAPIResult<T> | undefined): BxAPIResult<T> => {
                    if (old) {
                        return {
                            ...old,
                            data: old.data.map((row: T) => {
                                // @ts-ignore
                                if (row.Id === record.Id) {
                                    return {
                                        ...row,
                                        ...record,
                                    };
                                } else {
                                    return row;
                                }
                            }),
                        };
                    } else {
                        throw new Error("Unable to update cache");
                    }
                }
            );
        }
    );

    const {
        isLoading: isAdding,
        mutateAsync: doAdd,
        error: addError,
    } = useMutation(
        async (record: Partial<T>) => {
            try {
                return (await apiClient.post(`${endpoint}`, record)).data;
            } catch (e: any) {
                // @ts-ignore
                if (e?.response.data?.error) {
                    throw new Error(e?.response.data?.error);
                }
                throw e;
            }
        },
        {
            onMutate: async (newRecord) => {
                // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
                await queryClient.cancelQueries([endpoint]);

                // Snapshot the previous value
                const previousTodos = queryClient.getQueryData(queryKey);

                //New record with temp id
                const tmpRecord = {
                    Id: uuidv4(),
                    ...newRecord,
                };

                // Optimistically update to the new value
                queryClient.setQueryData(
                    queryKey,
                    (old: BxAPIResult<T> | undefined): BxAPIResult<T> => {
                        if (old) {
                            return {
                                ...old,
                                data: [
                                    //@ts-ignore
                                    ...old.data,
                                    //@ts-ignore
                                    tmpRecord,
                                ],
                            };
                        } else {
                            return {
                                data: [
                                    //@ts-ignore
                                    tmpRecord,
                                ],
                            };
                        }
                    }
                );

                // Return a context object with the snapshotted value
                return { previousTodos };
            },
            // If the mutation fails, use the context returned from onMutate to roll back
            onError: (err, newTodo, context) => {
                console.log(err, newTodo, context);
                queryClient.setQueryData(queryKey, context?.previousTodos);
            },
            // Always refetch after success:
            onSuccess: (result, _variables, _context) => {
                queryClient.invalidateQueries(queryKey);
                return result;
            },
        }
    );

    const deleteRecord = useCallback(
        async (record: any) => {
            if (record.id) {
                return doDelete(record.id);
            } else {
                return Promise.resolve();
            }
        },
        [doDelete]
    );

    const deleteIds = useCallback(
        async (records: any[]) => {
            if (records && records.length > 0) {
                return doDelete(records);
            } else {
                return Promise.resolve();
            }
        },
        [doDelete]
    );

    useEffect(() => {
        if (queryWithDelay !== queryFilter) {
            const delayedSearchFn = setTimeout(() => {
                setQueryFilter(queryWithDelay);
            }, 1500);

            return () => clearTimeout(delayedSearchFn);
        }
    }, [queryWithDelay, queryFilter]);

    return {
        isLoading: isFetching,
        data: apiData?.data || [],
        queryWithDelay,
        setQueryWithDelay,
        setQueryFilter,
        filters,
        setFilters,
        addFilters,
        setFilter,
        removeFilter,
        load,
        error,
        deleteRecord,
        deleteIds,
        isDeleting,
        isUpdating,
        doUpdate,
        isAdding,
        doAdd,
        addError,
        totalCount: apiData?.totalcount || 0,
    };
};

export default useData;
