import * as Yup from "yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, IconButton, InputAdornment, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useAuth from "../hooks/useAuth";
import { FormProvider, RHFTextField } from "./hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

type FormValuesProps = {
    username: string;
    password: string;
    remember: boolean;
    afterSubmit?: string;
};

export default function LoginForm() {
    const { login, isAuthenticated, user } = useAuth();

    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            .required("Username is required"),
        password: Yup.string().required("Password is required"),
    });

    const defaultValues = {
        username: "",
        password: "",
        remember: true,
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        setError,
        formState: { errors, isSubmitting },
    } = methods;

    const onSubmit = async (data: FormValuesProps) => {
        try {
            if (data.username && data.password) {
                await login(data.username, data.password);
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    setError("afterSubmit", {
                        type: "server",
                        message: "Invalid Username or Password",
                    });
                } else {
                    setError("afterSubmit", {
                        type: "server",
                        message: "Something went wrong",
                    });
                }
            } else {
                setError("afterSubmit", {
                    type: "server",
                    message: "Something went wrong",
                });
            }
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}
                {isAuthenticated && (
                    <Alert severity="success">
                        You are logged in {user?.fullname}
                    </Alert>
                )}

                <RHFTextField name="username" label="Username"/>

                <RHFTextField
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                >
                                    {
                                        <FontAwesomeIcon
                                            icon={
                                                showPassword
                                                    ? faEye
                                                    : faEyeSlash
                                            }
                                        />
                                    }
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Login
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
