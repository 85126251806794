import React from "react";
import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";

interface Props {
    title: string,
    children: React.ReactNode
}

const PageElement = ({ title, children }: Props) => (<>
    <Box sx={{ marginTop: 3 }}>
        <Paper elevation={2}>
            <AppBar position="static" sx={{ padding: 2 }}>
                {title}
            </AppBar>
            <Box sx={{ padding: { xs: 1, sm: 2 } }}>
                {children}
            </Box>
        </Paper>
    </Box>
</>)


export default PageElement;