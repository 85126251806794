import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAdminState from "../../hooks/useAdminState";
import { Pulje } from "../../@types";
import { Autocomplete, Box, ListItemText, TextField } from "@mui/material";
import useData from "../../hooks/useData";
import { fDateTime } from "../../utils/formatDateTime";

const SquadPicker = () => {
    const { t } = useTranslation();
    const segment = useAdminState(state => state.segment);
    const value = useAdminState(state => state.squad);
    const setValue = useAdminState(state => state.setSquad);

    const {
        data,
        isLoading,
        setFilter
    } = useData<Pulje>('puljer', {
        requireFilter: true,
    });


    useEffect(() => {
        if (segment) {
            setFilter('segment', { property: 'segment', value: segment.Id });
        }
    }, [setFilter, segment]);

    useEffect(() => {

        if (!value && data.length > 0) {
            setValue(data[0]);
        }
    }, [data, setValue, value]);

    return <Autocomplete
        options={data}
        getOptionLabel={(option) => `${fDateTime(option.start)}`}
        renderOption={(props, option) =>
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <ListItemText primary={fDateTime(option.start)}/>
            </Box>
        }
        value={value}
        onChange={(event, newValue) => {
            setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} label={t("Squad")} variant="outlined"/>}
        fullWidth
        disabled={isLoading || !segment}
    />;
}

export default SquadPicker;
