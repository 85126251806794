import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import apiClient from './apiClient';


export type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
            type: Key;
        }
        : {
            type: Key;
            payload: M[Key];
        };
};

export type AuthUser = null | Record<string, any>;

export type AuthState = {
    isAuthenticated: boolean;
    isInitialized: boolean;
    user: AuthUser;
};

export type BResContextType = {
    isAuthenticated: boolean;
    isInitialized: boolean;
    user: AuthUser;
    method: 'BRes';
    login: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    showLogin: boolean;
    setShowLogin: (show: boolean) => void;
}

enum Types {
    Initial = 'INITIALIZE',
    Login = 'LOGIN',
    Logout = 'LOGOUT',
    Register = 'REGISTER',
}

type BResAuthPayload = {
    [Types.Initial]: {
        isAuthenticated: boolean;
        user: AuthUser;
    };
    [Types.Login]: {
        user: AuthUser;
    };
    [Types.Logout]: undefined;
    [Types.Register]: {
        user: AuthUser;
    };
};

export type BxActions = ActionMap<BResAuthPayload>[keyof ActionMap<BResAuthPayload>];

const initialState: AuthState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const setSession = (accessToken: string | null) => {
    //nothing, handled by cookie
};

const BxReducer = (state: AuthState, action: BxActions) => {
    switch (action.type) {
        case 'INITIALIZE':
            return {
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
                user: action.payload.user
            };
        case 'LOGIN':
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user
            };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };

        default:
            return state;
    }
};

const AuthContext = createContext<BResContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
    children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
    const [state, dispatch] = useReducer(BxReducer, initialState);
    const [showLogin, setShowLogin] = useState(false);

    useEffect(() => {
        const initialize = async () => {
            try {
                const { data: sessionResponse } = await apiClient.get(`/users/current`);

                if (sessionResponse.success) {
                    dispatch({
                        type: Types.Initial,
                        payload: {
                            isAuthenticated: true,
                            user: { ...sessionResponse.data }
                        },
                    });
                    return;
                } else {
                    dispatch({
                        type: Types.Initial,
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                dispatch({
                    type: Types.Initial,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };

        initialize();
    }, []);

    const login = async (username: string, password: string) => {
        const response = await apiClient.post('/login', {
            username,
            password,
        });
        const { success } = response.data;
        if (success) {
            setSession(JSON.stringify({
                username: username,
                password: password
            }));
            dispatch({
                type: Types.Login,
                payload: {
                    user: response.data.data[0],
                },
            });

        } else {
            dispatch({
                type: Types.Initial,
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            });
        }
    };

    const logout = async () => {
        setSession(null);
        dispatch({ type: Types.Logout });
        await apiClient.post('/logout');
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'BRes',
                login,
                logout,
                showLogin,
                setShowLogin
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider };
