import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAdminState from "../../hooks/useAdminState";
import { PuljeSegment } from "../../@types";
import { Autocomplete, Box, ListItemText, TextField } from "@mui/material";
import useData from "../../hooks/useData";

const SegmentPicker = () => {
    const { t } = useTranslation();
    const tournament = useAdminState(state => state.tournament);
    const value = useAdminState(state => state.segment);
    const setValue = useAdminState(state => state.setSegment);

    const {
        data,
        isLoading,
        setFilter
    } = useData<PuljeSegment>('segmenter', {
        requireFilter: true,
    });


    useEffect(() => {
        if (tournament) {
            setFilter('turnering', { property: 'turnering', value: tournament.Id });
        }
    }, [setFilter, tournament]);

    useEffect(() => {

        if (!value && data.length > 0) {
            setValue(data[0]);
        }
    }, [data, setValue, value]);

    return <Autocomplete
        options={data}
        getOptionLabel={(option) => `${option.navn}`}
        renderOption={(props, option) =>
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <ListItemText primary={option.navn}/>
            </Box>
        }
        value={value}
        onChange={(event, newValue) => {
            setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} label={t("Segment")} variant="outlined"/>}
        fullWidth
        disabled={isLoading || !tournament}
    />;
}

export default SegmentPicker;
