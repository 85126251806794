import React, { useMemo } from "react";
import { Autocomplete, ListItemText, TextField, Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import apiClient from "../../apiClient";
import { ApiResult, ITournament } from "../../@types";
import { fDate } from "../../utils/formatDateTime";
import useAdminState from "../../hooks/useAdminState";
import { useTranslation } from "react-i18next";


const TournamentPicker = () => {
    const { t } = useTranslation();
    const value = useAdminState(state => state.tournament);
    const setValue = useAdminState(state => state.setTournament);
    const {
        data
    } = useQuery(['tournaments'], async () => (await apiClient.get<ApiResult<ITournament>>(`/turneringer/?limit=10000`)).data)

    const options = useMemo(() => {
        if (!data) return [];
        return data.results.filter((tournament) => tournament.admin);
    }, [data]);

    return <Autocomplete
        options={options}
        getOptionLabel={(option) => `${option.Id} - ${option.navn}`}
        renderOption={(props, option) =>
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <ListItemText primary={option.navn} secondary={`${fDate(option.dato)} - ${option.arrangor}`}/>
            </Box>
        }
        value={value}
        onChange={(event, newValue) => {
            setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} label={t("Tournament")} variant="outlined"/>}
        fullWidth
    />;
}

export default TournamentPicker;
