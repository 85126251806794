/* eslint-disable import/no-duplicates */
import { format } from 'date-fns';
import { nb } from 'date-fns/locale';


export function formatSquadDateTime(date: Date | string) {
    if (!date) {
        return ''
    }
    return format(new Date(date), 'iii yyyy-MM-dd HH:mm', { locale: nb });
}

export function fDate(date: Date | string | number, formatStr?: string): string {
    if (!date) {
        return ''
    }
    return format(new Date(date), formatStr || 'yyyy-MM-dd', { locale: nb });
}

export function fDateTime(date: Date | string | number | null) {
    if (!date) {
        return "";
    }
    return format(new Date(date), "yyyy-MM-dd HH:mm", { locale: nb });
}
