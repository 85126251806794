import React, { useState } from "react";
import { Outlet } from "react-router";
import { styled } from '@mui/material/styles';
import { HEADER, NAVBAR } from '../config';
import useCollapseDrawer from "../hooks/useCollapseDrawer";
import Box from '@mui/material/Box';
import NavbarVertical from "./navbar/NavbarVertical";
import AdminHeader from "../components/AdminHeader";

type MainStyleProps = {
    collapseClick: boolean;
};

const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
    flexGrow: 1,
    paddingTop: HEADER.MOBILE_HEIGHT + 24,
    paddingBottom: HEADER.MOBILE_HEIGHT + 24,
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
        paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
        transition: theme.transitions.create('margin-left', {
            duration: theme.transitions.duration.shorter,
        }),
        ...(collapseClick && {
            marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
        }),
    },
}));

const AdminLayout = () => {
    const { collapseClick, isCollapse } = useCollapseDrawer();
    const [open, setOpen] = useState(false);

    return (
        <Box
            sx={{
                display: { lg: 'flex' },
                minHeight: { lg: 1 },
            }}
        >
            <AdminHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)}/>

            <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)}/>

            <MainStyle collapseClick={collapseClick}>
                <Outlet/>
            </MainStyle>
        </Box>
    )
}

export default AdminLayout
