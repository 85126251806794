import { Controller, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

interface IProps {
    name: string;
}

export default function RHFTextField({ name, defaultValue = '', required, ...other }: IProps & TextFieldProps) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{ required }}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    name={field.name}
                    inputRef={field.ref}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    required={!!required}
                    {...other}
                />
            )}
        />
    );
}
