import { useRoutes } from "react-router-dom";
import { ElementType, lazy, Suspense } from "react";
import MainLayout from "../layout/MainLayout";
import PageElement from "../components/PageElement";
import Container from "@mui/material/Container";
import AdminLayout from "../layout/AdminLayout";


const Loadable = (Component: ElementType) => (props: any) =>
    (
        <Suspense fallback={
            <Container maxWidth="xl">
                <PageElement title="....">Loading...</PageElement>
            </Container>
        }>
            <Component {...props} />
        </Suspense>
    )
;

const Router = () => useRoutes([
    {
        path: "",
        element: <MainLayout/>,
        children: [
            {
                path: "",
                children: [
                    {
                        path: "",
                        element: <HomePage/>
                    },
                    {
                        path: 'turneringer',
                        children: [
                            {
                                path: "",
                                element: <TournamentsPage/>,
                            },
                            {
                                path: 'mine',
                                element: <MyTournamentsPage/>
                            },
                            {
                                path: ':tournamentIdGroup/:tournamentIdName/rapport',
                                element: <TournamentReportPage/>
                            },
                            {
                                path: ':tournamentIdGroup/:tournamentIdName/*',
                                element: <TournamentDetailsPage/>
                            }
                        ]
                    },
                    {
                        path: "bowlinghaller",
                        element: <AlleysPage/>
                    },
                    {
                        path: "om",
                        element: <AboutPage/>
                    },
                    {
                        path: "gjestebok",
                        element: <GuestbookPage/>
                    },
                    {
                        path: "user/create",
                        element: <CreateUserPage/>
                    },
                    {
                        path: "user/confirm",
                        element: <ConfirmUserPage/>
                    },
                    {
                        path: "user/confirm/:confirmationCode",
                        element: <ConfirmUserPage/>
                    },
                    {
                        path: "arkiv/:year/:tournamentIdName",
                        element: <ArchivePage/>
                    }
                ]
            }
        ]
    },
    {
        path: "admin",
        element: <AdminLayout/>,
        children: [
            {
                path: "",
                element: <AdminPage/>,
            },
            {
                path: "participants",
                element: <ParticipantsPage/>
            }

        ]
    }
])

const HomePage = Loadable(lazy(() => import("../pages/HomePage")));
const TournamentsPage = Loadable(lazy(() => import("../pages/TournamentsPage")));
const TournamentDetailsPage = Loadable(lazy(() => import("../pages/TournamentDetailsPage")));
const TournamentReportPage = Loadable(lazy(() => import("../pages/TournamentReportPage")));
const AlleysPage = Loadable(lazy(() => import("../pages/AlleysPage")));
const AboutPage = Loadable(lazy(() => import("../pages/AboutPage")));
const GuestbookPage = Loadable(lazy(() => import("../pages/GuestbookPage")));
const MyTournamentsPage = Loadable(lazy(() => import("../pages/MyTournamentsPage")));

//Archive
const ArchivePage = Loadable(lazy(() => import("../pages/ArchivePage")));

//Admin
const AdminPage = Loadable(lazy(() => import("../pages/admin")));
const ParticipantsPage = Loadable(lazy(() => import("../pages/admin/Participants")));

//User
const CreateUserPage = Loadable(lazy(() => import("../pages/user/CreatePage")));
const ConfirmUserPage = Loadable(lazy(() => import("../pages/user/ConfirmPage")));


export default Router
