import * as Yup from "yup";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFTextField } from "./hook-form";
import axios from "axios";
import apiClient from "../apiClient";

type FormValuesProps = {
    email: string;
    afterSubmit?: string;
};

export default function ResetPasswordForm({ onSuccess }: { onSuccess?: () => void }) {

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email().required("e-Mail is required"),
    });

    const defaultValues = {
        email: "",
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        setError,
        formState: { errors, isSubmitting },
    } = methods;

    const onSubmit = async (data: FormValuesProps) => {
        try {
            await apiClient.post("/resetpassword", data);
            if (onSuccess) {
                onSuccess();
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                setError("afterSubmit", {
                    type: "server",
                    message: "Something went wrong",
                });
            } else {
                setError("afterSubmit", {
                    type: "server",
                    message: "Something went wrong",
                });
            }
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                {!!errors.afterSubmit && (
                    <Alert severity="error">{errors.afterSubmit.message}</Alert>
                )}

                <RHFTextField
                    name="email"
                    label="e-Mail"
                    type="email"
                />

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Reset
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
