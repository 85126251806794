import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";

Sentry.init({
    dsn: "https://e0b40a2f53ee48809be9e4fd55594080@o472706.ingest.sentry.io/6760819",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <HelmetProvider>
        <BrowserRouter>
            <CollapseDrawerProvider>
                <App/>
            </CollapseDrawerProvider>
        </BrowserRouter>
    </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
