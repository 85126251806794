// @mui
import { Stack, Typography } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
    const { user } = useAuth();

    return (
        <Stack
            spacing={3}
            sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
        >

            <div>
                <Typography gutterBottom variant="subtitle1">
                    {user?.displayName}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
                    yoo
                </Typography>
            </div>
        </Stack>
    );
}
