import React from "react";
import Header from "../components/header/Header";
import { Outlet } from "react-router";

const MainLayout = () => (
    <>
        <Header/>

        <div>
            <Outlet/>
        </div>
    </>
)


export default MainLayout