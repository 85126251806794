const navConfig = [
    {
        subheader: 'Players',
        items: [
            { title: 'Participants', path: "/admin/participants" },
            { title: 'Double', path: "123" },
            { title: 'Qualification', path: "123" },
            { title: 'Logs', path: "123" },
        ],
    },
    {
        subheader: 'Results',
        items: [
            { title: 'Games', path: "123" },
            { title: 'Matches', path: "123" },
        ],
    },
    {
        subheader: 'Utilities',
        items: [
            { title: 'Player Forms', path: "123" },
            { title: 'Results sheet', path: "123" },
            { title: 'Others', path: "123" },
        ]
    },
    {
        subheader: 'Settings',
        items: [
            { title: 'Tournament', path: "123" },
            { title: 'Create players', path: "123" },
        ]
    },
    {
        subheader: 'Database',
        items: [
            { title: 'Players', path: "123" },
            { title: 'Clubs', path: "123" },
            { title: 'Users', path: "123" },
        ]
    }
];

export default navConfig;
