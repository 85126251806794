import create from "zustand";
import { ITournament, Pulje, PuljeSegment } from "../@types";

interface AdminState {
    tournament: ITournament | null;
    setTournament: (tournament: ITournament | null) => void;
    segment: PuljeSegment | null;
    setSegment: (segment: PuljeSegment | null) => void;
    squad: Pulje | null;
    setSquad: (squad: Pulje | null) => void;
}

const useAdminState = create<AdminState>((set) => ({
    tournament: null,
    segment: null,
    squad: null,
    setTournament: (tournament) => set({ tournament, segment: null, squad: null }),
    setSegment: (segment) => set({ segment }),
    setSquad: (squad) => set({ squad }),
}));

export default useAdminState;
