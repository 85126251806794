import React from "react";
import { HEADER, NAVBAR } from "../config";
import useOffSetTop from "../hooks/useOffSetTop";
import cssStyles from "../utils/cssStyles";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import TournamentPicker from "./admin/TournamentPicker";
import SegmentPicker from "./admin/SegmentPicker";
import { Stack } from "@mui/material";
import SquadPicker from "./admin/SquadPicker";

type RootStyleProps = {
    isCollapse: boolean;
    isOffset: boolean;
    verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
    shouldForwardProp: (prop) =>
        prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    height: HEADER.MOBILE_HEIGHT,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('lg')]: {
        height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
        ...(isCollapse && {
            width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
        }),
        ...(isOffset && {
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
        }),
        ...(verticalLayout && {
            width: '100%',
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
            // backgroundColor: theme.palette.background.default,
        }),
    },
}));

type Props = {
    onOpenSidebar: VoidFunction;
    isCollapse?: boolean;
    verticalLayout?: boolean;
};

const AdminHeader = ({
                         isCollapse = false,
                         verticalLayout = false,
                     }: Props) => {
    const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;


    return (
        <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
            <Toolbar
                sx={{
                    minHeight: '100% !important',
                    px: { lg: 5 }
                }}
            >

                <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
                    <TournamentPicker/>
                    <SegmentPicker/>
                    <SquadPicker/>
                </Stack>

            </Toolbar>
        </RootStyle>
    )
}

export default AdminHeader
