import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {
    Link
} from 'react-router-dom';
import { Menu, MenuItem, Stack, SvgIcon } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoginForm from "../LoginForm";
import NewPasswordForm from "../NewPasswordForm";
import { useTranslation } from "react-i18next";
import { ReactComponent as NorwegianFlag } from "./no.svg"
import { ReactComponent as EnglishFlag } from "./en.svg"
import { changeLanguage } from "i18next";
import ResetPasswordForm from "../ResetPasswordForm";

interface MenuConfig {
    name: string,
    link: string,
    aclPoint?: string
}

const pages: MenuConfig[] = [
    {
        name: 'Home',
        link: ''
    },
    {
        name: 'Tournaments',
        link: 'turneringer'
    },
    {
        name: 'Alleys',
        link: 'bowlinghaller'
    },
    {
        name: 'Guest Book',
        link: 'gjestebok'
    },
    {
        name: 'About',
        link: 'om'
    }
]

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    if (!name) {
        return {};
    }

    const arr = name.split(' ');
    const first = arr[0].charAt(0);
    const last = arr.length > 1 ? arr[1].charAt(0) : '';

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${first}${last}`,
    };
}

const MainMenu = () => {
    const { user, isAuthenticated, logout, showLogin, setShowLogin } = useAuth();

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [showChangePassword, setShowChangePassword] = React.useState(false);
    const [showResetPassword, setShowResetPassword] = React.useState(false);

    const { t, i18n } = useTranslation();

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (!isAuthenticated) {
            setShowLogin(true);
        } else {
            setAnchorElUser(event.currentTarget);
        }
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = () => {
        logout();
        handleCloseUserMenu();
    }

    const handleClose = () => {
        setShowLogin(false);
    };

    const handleChangePassword = () => {
        setShowChangePassword(true);
        setAnchorElUser(null);
    }


    return (
        <>
            <Dialog open={showLogin && !isAuthenticated} onClose={handleClose}>
                <DialogTitle>Login</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter your username and password to login.
                    </DialogContentText>
                    <LoginForm/>
                </DialogContent>

                <Stack direction="row" spacing={2} sx={{ p: 2 }} justifyContent="center">
                    <Button
                        onClick={() => {
                            setShowLogin(false);
                            setShowResetPassword(true)
                        }}
                    >{t("Reset Password")}</Button>
                    <Button
                        component={Link}
                        to="/user/create"
                        onClick={() => {
                            setShowLogin(false);
                        }}
                    >{t("Create user")}</Button>
                </Stack>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showChangePassword} onClose={handleClose}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Change your password
                    </DialogContentText>
                    <NewPasswordForm onSuccess={() => setShowChangePassword(false)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowChangePassword(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={showResetPassword} onClose={handleClose}>
                <DialogTitle>Reset Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Reset your password
                    </DialogContentText>
                    <ResetPasswordForm onSuccess={() => setShowResetPassword(false)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowResetPassword(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>


            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            BowlingRes
                        </Typography>

                        <IconButton
                            sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
                            onClick={() => {
                                changeLanguage(i18n.language === 'nb' ? 'en' : 'nb');
                            }}

                        >
                            <SvgIcon>
                                {i18n.language === 'nb' ? <NorwegianFlag/> :
                                    <EnglishFlag/>}
                            </SvgIcon>
                        </IconButton>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <MenuItem
                                    onClick={() => changeLanguage(i18n.language === 'nb' ? 'en' : 'nb')}

                                >
                                    <SvgIcon>
                                        {i18n.language === 'nb' ? <NorwegianFlag/> :
                                            <EnglishFlag/>}
                                    </SvgIcon>
                                </MenuItem>
                                {pages.map((page) => (
                                    <MenuItem
                                        key={page.name}
                                        component={Link}
                                        to={page.link}
                                        onClick={handleCloseNavMenu}
                                    >
                                        <Typography textAlign="center">{t(page.name)}</Typography>
                                    </MenuItem>
                                ))}
                                {
                                    isAuthenticated && (user?.Access?.SerieReg || user?.Access._Admin) && (
                                        <Button
                                            // component={Link}
                                            href="https://www.bowlingres.no/registrering/app5.0/"
                                            target="_blank"
                                            sx={{ my: 2, color: 'white', display: 'block' }}
                                        >
                                            <Typography textAlign="center">Admin</Typography>
                                        </Button>
                                    )
                                }
                            </Menu>
                        </Box>

                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            BowlingRes
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "space-evenly" }}>
                            {pages.map((page) => (
                                <Button
                                    component={Link}
                                    key={page.name}
                                    to={page.link}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {t(page.name)}
                                </Button>
                            ))}
                            {
                                isAuthenticated && (user?.Access?.SerieReg || user?.Access._Admin) && (
                                    <Button
                                        component={Link}
                                        to="registrering/app5.0/"
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >Admin</Button>
                                )
                            }
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title={!isAuthenticated || user === null ? 'Login' : user.RealName}>
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar
                                        alt={!isAuthenticated || user === null ? 'Login' : user.RealName}
                                        src={isAuthenticated ? undefined : "/images/avatar.svg"}
                                        {...stringAvatar(user?.RealName)}
                                    />

                                </IconButton>
                            </Tooltip>

                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem
                                    component={Link}
                                    onClick={handleCloseUserMenu}
                                    to="/turneringer/mine"
                                >
                                    <Typography textAlign="center">{t("My Tournaments")}</Typography>
                                </MenuItem>,
                                <MenuItem onClick={handleChangePassword}>
                                    <Typography textAlign="center">{t("Change Password")}</Typography>
                                </MenuItem>,
                                <MenuItem onClick={handleLogout}>
                                    <Typography textAlign="center">{t("Logout")}</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    )
}

export default MainMenu
