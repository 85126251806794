import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    nb: {
        translation: {
            "Home": "Hjem",
            "Tournaments": "Turneringer",
            "Alleys": "Haller",
            "Guest Book": "Gjestebok",
            "About": "Om",

            "My Tournaments": "Mine turneringer",
            "Change Password": "Endre passord",
            "Logout": "Logg ut",

            "Bowling + Tournaments + Results = BowlingRes": "Bowling + Turneringer + Resultater = BowlingRes",
            "On this page you'll find all your stuff about results and bookings from Norwegian Bowling Tournaments. Enjoy!": "På denne siden finner du alt du trenger for å følge med på resultater og bookinger fra norske bowlingturneringer. Velkommen!",
            "News": "Nyheter",
            "Featured Tournaments": "Utvalgte Turneringer",
            "No news is good news": "Ingen nyheter er gode nyheter",

            "Booking": "Påmelding",
            "Squad": "Pulje",


            "Bowling Tournaments": "Bowling Turneringer",
            "Search": "Søk",

            "Bowling Alleys with online scoring": "Bowling Haller med online scoring",
            "We try to gather alleys with online scoring here.": "Vi prøver å samle bowlinghallene med online scoring her.",

            "BowlingRes Guest Book": "BowlingRes Gjestebok",
            "This guest book is suited for generic comments about the site, feedback and suggestions.": "Denne gjesteboken er til generelle kommentarer om siden, tilbakemeldinger og forslag.",
            "Each tournament has it's own guest book, more suited for specific comments about the tournament.": "Hver turnering har sin egen gjestebok, mer egnet for spesifikke kommentarer om turneringen.",
            "All new entries are published on the front page, this page shows entries not linked to a specific tournament.": "Alle nye innlegg publiseres på forsiden, denne siden viser innlegg som ikke er knyttet til en spesifikk turnering.",
            "Write a new entry": "Skriv et nytt innlegg",
            "Message": "Melding",
            "No entries": "Ingen innlegg",
            "No access": "Ingen tilgang",
            "Something went wrong": "Noe gikk galt",
            "Login Required": "Innlogging kreves",
            "Message is required": "Melding er påkrevd",

            "Tournament": "Turnering",
            "Results": "Resultater",
            "Participants": "Deltakere",

            "No results yet": "Ingen resultater ennå",
            "Squads": "Puljer",
            "Details": "Detaljer",
            "All entries": "Alle starter",
            "Matches": "Kamper",
            "Class": "Klasse",
            "Club": "Klubb",
            "Team": "Lag",
            "Double": "Dobbel",

            "Choose player": "Velg spiller",
            "Waiting list": "Venteliste",

            "Groups": "Klasser",

            "Tournament is hosted by": "Turneringen arrangeres av",
            "at": "i",

            "Tournament homepage": "Turneringens hjemmeside",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        // lng: "no", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;